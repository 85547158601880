import {
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  Input,
  Link,
  Textarea,
  Tooltip,
} from "@chakra-ui/react";
import { Select, SelectOption } from "../../../components/Select/Select";
import { useState } from "react";
import FileUpload from "../../../components/fileUpload/FileUpload";
import { AddIcon, CloseIcon, DownloadIcon } from "@chakra-ui/icons";
import InputWithButton from "../../../components/InputWithButton/InputWithButton";
import { isValidEmail } from "../utils";

interface NewExperimentDrawerProps {
  isOpen: boolean;
  onClose: () => void;
}

const NewExperimentDrawer = ({ onClose, isOpen }: NewExperimentDrawerProps) => {
  const [collaborators, setCollaborators] = useState<string[]>([]);

  const options: SelectOption[] = [
    {
      id: 1,
      value: "Manually",
    },
    {
      id: 2,
      value: "File upload",
    },
  ];

  const [isDefinedManually, setIsDefinedManually] = useState<SelectOption>(
    options[0],
  );

  return (
    <Drawer size={"lg"} placement={"right"} onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader borderBottomWidth="1px">
          Create an experiment
        </DrawerHeader>
        <DrawerCloseButton />
        <DrawerBody className="mt-4">
          <FormControl>
            <FormLabel>Experiment Name</FormLabel>
            <Input variant={"outline"} />

            <FormLabel className="mt-5">Experiment Description</FormLabel>
            <Textarea variant={"outline"} />

            <Divider className="mt-5" borderWidth={"1px"} />

            <FormLabel className="mt-5">
              Choose how to define the experiment parameters
            </FormLabel>
            <Select options={options} setValue={setIsDefinedManually} />
            <FormHelperText>
              You can either define the parameters manually, or drag and drop a
              structured file.
            </FormHelperText>

            {isDefinedManually.id === options[0].id && (
              <FormLabel className="mt-5">
                TODO - Defining manually. See NewExperimentDrawer.tsx
              </FormLabel>
            )}

            {isDefinedManually.id === options[1].id && (
              <>
                <FormLabel className="mt-4">Upload a file</FormLabel>
                <FileUpload />
                <FormHelperText>
                  The file upload should contain the name of the variables in
                  the first row, and the independent variable must be on the
                  left most column. Download an example here{" "}
                  <Link color="teal" href="/todo">
                    example.csv <DownloadIcon />
                  </Link>
                </FormHelperText>
              </>
            )}

            <Divider className="mt-5" borderWidth={"1px"} />
            <FormLabel className="mt-4">Invite Collaborators</FormLabel>
            <InputWithButton
              icon={<AddIcon />}
              placeholder="john.snow@company.com"
              buttonText="Invite"
              onSubmit={(newCollaborator: string) => {
                //TODO - 2. Check this user exists and has a license associated with them
                if (!isValidEmail(newCollaborator)) {
                  return;
                }
                if (collaborators.includes(newCollaborator)) {
                  return;
                }

                const newCollaborators = collaborators.concat([
                  newCollaborator,
                ]);
                setCollaborators(newCollaborators);
              }}
            />

            <div className="mt-4">
              {collaborators.map((collaborator, index) => {
                return (
                  <div
                    key={index}
                    className="bg-[#00000011] my-2 rounded-xl px-3 py-1 text-sm font-semibold w-fit"
                  >
                    {collaborator}
                    <Tooltip rounded={"xl"} label="remove collaborator">
                      <IconButton
                        size={"xs"}
                        rounded={"3xl"}
                        marginLeft={"2"}
                        colorScheme="gray"
                        aria-label="remove collaborator"
                        icon={<CloseIcon />}
                        onClick={() => {
                          const collaboratorsCopy =
                            structuredClone(collaborators);
                          collaboratorsCopy.splice(index, 1);
                          setCollaborators(collaboratorsCopy);
                        }}
                      />
                    </Tooltip>
                  </div>
                );
              })}
            </div>
          </FormControl>
        </DrawerBody>
        <DrawerFooter borderTopWidth="1px">
          <Button
            className="mr-2"
            colorScheme="red"
            variant="outline"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button colorScheme="red" variant="solid">
            Create Experiment
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default NewExperimentDrawer;
