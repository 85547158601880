import { ReactNode } from "react";

interface PaddingProps {
  className?: string;
  children?: ReactNode;
}

const Padding = ({ className, children }: PaddingProps) => {
  return (
    <div className={className + " w-full"}>
      <div className="max-w-[90vw] sm:max-w-[85vw] md:max-w-2xl lg:max-w-4xl xl:max-w-5xl mx-auto">
        {children}
      </div>
    </div>
  );
};

export default Padding;
