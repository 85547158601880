import { useState } from "react";
import { Experiment } from "../../../types/types";
import { CreateExperiment } from "../../../api/securePlatformApi";

const useExperiments = () => {
  const [experiments, setExperiments] = useState<Experiment[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const archiveExperiment = async (experimentId: string) => {
    setIsLoading(true);
    console.log(experimentId);
    //TODO...
    // 1. call api to archive experiment
    // 2. call api to get all experiments
    // 3. set experiments to new set of experiments
    setExperiments([]);
    setIsLoading(false);
  };

  const deleteExperiment = async (experimentId: string) => {
    setIsLoading(true);
    console.log(experimentId);
    //TODO...
    // 1. call api to delete experiment
    // 2. call api to get all experiments
    // 3. set experiments to new set of experiments
    setExperiments([]);
    setIsLoading(false);
  };

  const getExperiments = async () => {
    setIsLoading(true);
    //TODO...
    // 1. call api to get all experiments
    // 2. set experiments to new set of experiments
    setIsLoading(false);
  };

  const createExperiment = async (experiment: Experiment) => {
    setIsLoading(true);
    try {
      CreateExperiment(experiment);
    } catch (e) {
      //TODO handle error
      console.error(e);
      return;
    }
    // 1. all api to get all experiments
    // 2. set experiments to new set of experiments
    setIsLoading(false);
  };

  return {
    experiments,
    isLoading,
    archiveExperiment,
    getExperiments,
    createExperiment,
    deleteExperiment,
  };
};

export default useExperiments;
