import { ReactNode } from "react";

interface HeaderLinkProps {
  className?: string;
  children?: ReactNode;
  href: string;
}

const HeaderLink = ({ className, children, href }: HeaderLinkProps) => {
  return (
    <a
      className={
        "text-sm pr-2 md:pr-6 lg:pr-10 self-center " + (className ?? "")
      }
      href={href}
    >
      {children}
    </a>
  );
};

export default HeaderLink;
