import { Box, Button, Input } from "@chakra-ui/react";
import { ReactElement, useState } from "react";

interface InputWithButtonProps {
  icon: ReactElement;
  placeholder: string;
  buttonText: string;
  className?: string;
  onSubmit?: (value: string) => void;
}

const InputWithButton = ({
  icon,
  placeholder,
  className,
  buttonText,
  onSubmit,
}: InputWithButtonProps) => {
  const [value, setValue] = useState<string>("");

  return (
    <Box
      className={className}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <Input
        placeholder={placeholder}
        paddingTop={"0px"}
        roundedRight={"none"}
        borderRight={"none"}
        onChange={(e) => {
          setValue(e.target.value);
        }}
      />
      <Button
        roundedLeft={"none"}
        colorScheme="red"
        rightIcon={icon}
        margin={0}
        onClick={() => {
          if (onSubmit) {
            onSubmit(value);
          }
        }}
      >
        {buttonText}
      </Button>
    </Box>
  );
};

export default InputWithButton;
