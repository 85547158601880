import { ReactNode } from "react";

interface HeaderLinkProps {
  icon: ReactNode;
  children?: ReactNode;
  selected: boolean;
  href: string;
}

const NavLink = ({ children, icon, selected, href }: HeaderLinkProps) => {
  return (
    <>
      <a
        className={
          selected
            ? "text-md pr-10 self-center inline-flex pb-2 font-semibold text-red-700"
            : "text-md pr-10 self-center inline-flex pb-2 font-light"
        }
        href={href}
      >
        <div className="pr-2 self-center">{icon}</div>
        <div className="self-center">{children}</div>
      </a>
    </>
  );
};

export default NavLink;
