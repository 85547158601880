import starterTeam from "./../../../images/starter-team.png";
import professionalTeam from "./../../../images/professional-team.png";
import enterprise from "./../../../images/enterprise.png";
import { Badge, Button } from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";

const Subscriptions = () => {
  return (
    <div className="relative">
      <div className="absolute left-[50%] top-0 bottom-0 bg-green w-[100vw] -z-10 -translate-x-2/4"></div>
      <div className="py-20">
        <p className="text-red-700 font-poppins font-bold">PRICING</p>
        <h2 className="font-prosto text-4xl font-semibold pb-8">
          Choose the right plan
        </h2>
        <div className="flex gap-4 flex-col lg:flex-row">
          <div className="grow basis-0 border-[#00000022] border-[1px] rounded-xl p-6">
            <img alt="" className="max-w-[150px] lg:mt-5" src={starterTeam} />
            <h3 className="font-bold text-xl">Free Tier</h3>
            <p className="font-light text-sm text-gray-700  mt-2">
              Get started for free! Ideal for sharing experimental data and
              enabling collaboration. Whilst benefitting from advanced security
              and access controls.
            </p>
            <div className="flex flex-row mt-8">
              <p className="font-bold text-4xl">£0</p>
              <div className="flex flex-col text-xs ml-1 text-gray-700 font-light self-center">
                <p>per seat</p>
                <p>per month</p>
              </div>
            </div>
            <Button
              as={"a"}
              href="/dashboard/experiment"
              colorScheme="red"
              width={"-webkit-fill-available"}
              mt={"6"}
            >
              Get Started
            </Button>
            <h4 className="text-sm mt-4 mb-1">This includes:</h4>
            <ul>
              <li className="text-sm">
                <CheckCircleIcon
                  w={"3"}
                  h={"3"}
                  color={"gray.600"}
                  className="mr-3"
                />
                No fees
              </li>
              <li className="text-sm">
                <CheckCircleIcon
                  w={"3"}
                  h={"3"}
                  color={"gray.600"}
                  className="mr-3"
                />
                5 live experiments
              </li>
              <li className="text-sm">
                <CheckCircleIcon
                  w={"3"}
                  h={"3"}
                  color={"gray.600"}
                  className="mr-3"
                />
                3 collaborators per experiment
              </li>
              <li className="text-sm">
                <CheckCircleIcon
                  w={"3"}
                  h={"3"}
                  color={"gray.600"}
                  className="mr-3"
                />
                Self hosted analysis
              </li>
            </ul>
          </div>
          <div className="grow basis-0 border-[#00000022] border-[1px] rounded-xl p-6 bg-[#00000009]">
            <Badge>Most popular</Badge>
            <img alt="" className="max-w-[150px]" src={professionalTeam} />
            <h3 className="font-bold text-xl">Professional Team</h3>
            <p className="font-light text-sm text-gray-700 mt-2">
              Enables team collaboration, API access, and hosted analysis,
              empowering users with advanced features for streamlined
              experimentation and data sharing.
            </p>
            <div className="flex flex-row mt-8">
              <p className="font-bold text-4xl">£100</p>
              <div className="flex flex-col text-xs ml-1 text-gray-700 font-light self-center">
                <p>per seat</p>
                <p>per month</p>
              </div>
            </div>
            <Button
              as={"a"}
              href="/dashboard/account-settings"
              colorScheme="red"
              width={"-webkit-fill-available"}
              mt={"6"}
            >
              Buy Licenses
            </Button>
            <h4 className="text-sm mt-4 mb-1">This includes:</h4>
            <ul>
              <li className="text-sm">
                <CheckCircleIcon
                  w={"3"}
                  h={"3"}
                  color={"gray.600"}
                  className="mr-3"
                />
                API access
              </li>
              <li className="text-sm">
                <CheckCircleIcon
                  w={"3"}
                  h={"3"}
                  color={"gray.600"}
                  className="mr-3"
                />
                Unlimited experiments
              </li>
              <li className="text-sm">
                <CheckCircleIcon
                  w={"3"}
                  h={"3"}
                  color={"gray.600"}
                  className="mr-3"
                />
                Unlimited collaborators
              </li>
              <li className="text-sm">
                <CheckCircleIcon
                  w={"3"}
                  h={"3"}
                  color={"gray.600"}
                  className="mr-3"
                />
                Hosted analysis
              </li>
            </ul>
          </div>
          <div className="grow basis-0 border-[#00000022] border-[1px] rounded-xl p-6">
            <img alt="" className="max-w-[150px] lg:mt-5" src={enterprise} />
            <h3 className="font-bold text-xl">Enterprise</h3>
            <p className="font-light text-sm text-gray-700  mt-2">
              Everything and more: Enjoy unlimited API access, real-time data
              analysis, SSO, and dedicated face-to-face support, including
              development assistance.
            </p>
            <div className="flex flex-row mt-8">
              <p className="font-bold text-4xl">£400</p>
              <div className="flex flex-col text-xs ml-1 text-gray-700 font-light self-center">
                <p>per seat</p>
                <p>per month</p>
              </div>
            </div>
            <Button
              as={"a"}
              href="/dashboard/account-settings"
              colorScheme="red"
              width={"-webkit-fill-available"}
              mt={"6"}
            >
              Buy Licenses
            </Button>
            <h4 className="text-sm mt-4 mb-1">This includes:</h4>
            <ul>
              <li className="text-sm">
                <CheckCircleIcon
                  w={"3"}
                  h={"3"}
                  color={"gray.600"}
                  className="mr-3"
                />
                Unlimited API access
              </li>
              <li className="text-sm">
                <CheckCircleIcon
                  w={"3"}
                  h={"3"}
                  color={"gray.600"}
                  className="mr-3"
                />
                Real-time hosted analysis
              </li>
              <li className="text-sm">
                <CheckCircleIcon
                  w={"3"}
                  h={"3"}
                  color={"gray.600"}
                  className="mr-3"
                />
                Single sign on (SSO)
              </li>
              <li className="text-sm">
                <CheckCircleIcon
                  w={"3"}
                  h={"3"}
                  color={"gray.600"}
                  className="mr-3"
                />
                Face to face support
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subscriptions;
