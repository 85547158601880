import Demo from "./components/Demo";
import FeaturesWeOffer from "./components/FeaturesWeOffer";
import Footer from "./components/Footer";
import Hero from "./components/Hero";
import Subscriptions from "./components/Subscriptions";

const LandingPage = () => {
  return (
    <>
      <Hero />
      <FeaturesWeOffer />
      <Demo />
      <Subscriptions />
      <Footer />
    </>
  );
};

export default LandingPage;
