import { ReactNode } from "react";
import { PieChart, Send, Shield, Terminal } from "react-feather";

interface FeatureTileProps {
  icon: ReactNode;
  heading: string;
  description: string;
  className: string;
}

const FeatureTile = ({
  icon,
  heading,
  description,
  className,
}: FeatureTileProps): ReactNode => {
  return (
    <div
      className={
        "bg-[#ffffff30] rounded-xl border-2 border-[#00000050] px-6 py-8 " +
        className
      }
    >
      {icon}
      <h3 className="font-bold text-lg font-prosto mt-4">{heading}</h3>
      <p className="font-poppins font-light text-sm mt-2">{description}</p>
    </div>
  );
};

const FeaturesWeOffer = () => {
  const featureTile1 = {
    icon: <Send size={30} />,
    heading: "Create, share and participate in experiments",
    description: "Research data in one place. Accessible by the whole team.",
    className: "lg:-rotate-1",
  };

  const featureTile2 = {
    icon: <Terminal size={30} />,
    heading: "Insert data via the dashboard, or the API",
    description:
      "You can speed things up by integrating with our API. Or you can keep it simple. ",
    className: "lg:rotate-1",
  };

  const featureTile3 = {
    icon: <Shield size={30} />,
    heading: "You data is safe, secure and backed up",
    description:
      "Add deletion protection on your data, or even restore to a point in time.",
    className: "lg:rotate-1",
  };

  const featureTile4 = {
    icon: <PieChart size={30} />,
    heading: "Analyse your data how you want, when you want",
    description:
      "You can take advantage of Labvira to crunch your data in real time.",
    className: "lg:-rotate-1",
  };

  const heading = (
    <>
      <p className="text-red-700 font-poppins font-bold">FEATURES</p>
      <h2 className="font-prosto text-4xl font-semibold">
        Check out our core features
      </h2>
      <p className="font-poppins font-light mt-2">
        Technical or not, if you want to run and share experiment data we have
        you covered. There are so many easy to use features built into Labvira,
        why not watch our demo video below!
      </p>
    </>
  );

  return (
    <div id="#features" className="relative">
      <div className="absolute left-[50%] top-0 bottom-0 bg-green w-[100vw] -z-10 -translate-x-2/4"></div>
      <div className="py-20">
        <div className="flex flex-col md:flex-row gap-10">
          <div className="md:hidden">{heading}</div>
          <div className="flex-[60]">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 gap-2">
              {FeatureTile(featureTile1)}
              {FeatureTile(featureTile2)}
              {FeatureTile(featureTile3)}
              {FeatureTile(featureTile4)}
            </div>
          </div>
          <div className="flex-[40] self-center hidden md:block">{heading}</div>
        </div>
      </div>
    </div>
  );
};

export default FeaturesWeOffer;
