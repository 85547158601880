import { Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";

const SearchBox = () => {
  return (
    <div className="py-7 grow">
      <InputGroup>
        <InputLeftElement pointerEvents="none">
          <SearchIcon color="#353A44" />
        </InputLeftElement>
        <Input colorScheme="red" variant={"filled"} placeholder={"Search"} />
      </InputGroup>
    </div>
  );
};

export default SearchBox;
