import { AddIcon } from "@chakra-ui/icons";
import {
  Button,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
} from "@chakra-ui/react";
import LiveExperimentsTable from "./components/LiveExperimentsTable";
import NewExperimentDrawer from "./components/NewExperimentDrawer";
import useExperiments from "./hooks/useExperiments";

const ExperimentsPage = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { experiments, isLoading } = useExperiments();

  return (
    <>
      <div className="flex">
        <h1 className="font-poppins text-3xl font-semibold">Experiments</h1>
        <div className="grow"></div>
        <Button
          colorScheme={"red"}
          size={"sm"}
          rightIcon={<AddIcon />}
          onClick={onOpen}
        >
          Create
        </Button>
      </div>

      <Tabs colorScheme={"red"}>
        <TabList>
          <Tab>Live Experiments</Tab>
          <Tab>Archived Experiments</Tab>
        </TabList>

        <TabPanels>
          <TabPanel padding={"0"}>
            <LiveExperimentsTable />
          </TabPanel>
          <TabPanel padding={"0"}>
            <p>TODO - see ExperimentsPage.tsx</p>
          </TabPanel>
        </TabPanels>
      </Tabs>

      <NewExperimentDrawer onClose={onClose} isOpen={isOpen} />
    </>
  );
};

export default ExperimentsPage;
