import { useRef, useState } from "react";
import { CheckCircle, Upload } from "react-feather";
import { detectVariables, FileError } from "./utils";

const FileUpload = () => {
  const inputFile = useRef<HTMLInputElement | null>(null);
  const [file, setFile] = useState<File>();
  const [independentVariable, setIndependentVariable] = useState<string>();
  const [dependentVariables, setDependentVariables] = useState<string[]>();
  const [fileUploadError, setFileUploadError] = useState<FileError | null>();

  const onButtonClick = () => {
    inputFile?.current?.click();
  };

  // TODO - delete once these variables are in use...
  console.log(independentVariable);
  console.log(dependentVariables);

  return (
    <>
      <input
        type="file"
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        id="file"
        ref={inputFile}
        style={{ display: "none" }}
        onChange={(event) => {
          if (event.target?.files) {
            const file = event.target.files[0];
            let variables;
            try {
              variables = detectVariables(file);
              setFile(file);
              setIndependentVariable(variables.independentVariable);
              setDependentVariables(variables.dependentVariables);
              setFileUploadError(null);
            } catch (e) {
              setFileUploadError(e as FileError);
            }
          }
        }}
      />
      <section
        className="border-dashed border-2 rounded-lg hover:bg-gray-100 w-full"
        onClick={onButtonClick}
        onDrop={(event) => {
          event.preventDefault();
          const file = event.dataTransfer.files[0];
          let variables;
          try {
            variables = detectVariables(file);
            setFile(file);
            setIndependentVariable(variables.independentVariable);
            setDependentVariables(variables.dependentVariables);
            setFileUploadError(null);
          } catch (e) {
            setFileUploadError(e as FileError);
          }
        }}
        onDragOver={(event) => event.preventDefault()}
      >
        <div className="w-fit mx-auto py-10">
          {!file && <Upload size={"24px"} className="mx-auto" />}
          {file && (
            <div className="bg-[#00ff0033] rounded-3xl py-2 px-5 flex flex-row w-fit mx-auto">
              <>{file.name}</>
              <CheckCircle className="ml-3" color="green" />
            </div>
          )}
          <p className="w-fit mx-auto text-md pt-2">
            {!file && <>Drag and drop here to upload</>}
            {file && <>Drag and drop here try another file</>}
          </p>

          {!fileUploadError && (
            <p className="w-fit mx-auto text-sm pt-1">.csv, .xlsx up to 5MB</p>
          )}
          {fileUploadError && (
            <p className="w-fit mx-auto text-sm pt-1 text-red-700 font-bold">
              File must be of type .csv, .xlsx
            </p>
          )}
        </div>
      </section>
    </>
  );
};

export default FileUpload;
