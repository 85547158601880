import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Header from "./components/header/Header";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import DashboardLayout from "./components/dashboardLayout/DashboardLayout";
import ExperimentsPage from "./pages/experimentsPage/ExperimentsPage";
import { useEffect } from "react";
import LandingPage from "./pages/landingPage/LandingPage";
import AnalysisPage from "./pages/analysisPage/AnalysisPage";
import { Authenticator } from "@aws-amplify/ui-react";
import { Amplify } from "aws-amplify";
import "@aws-amplify/ui-react/styles.css";

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolClientId: "2hfpo3qh3e21ug07qq4ra3h0ia",
      userPoolId: "eu-west-2_1o8j3y7Ve",
      userAttributes: {
        given_name: {
          required: true,
        },
        family_name: {
          required: true,
        },
      },
      loginWith: {
        // Optional
        oauth: {
          domain:
            "https://preprod-user-pool-domain-557690588499.auth.eu-west-2.amazoncognito.com",
          scopes: [
            "openid",
            "email",
            "phone",
            "profile",
            "aws.cognito.signin.user.admin",
          ],
          redirectSignIn: [
            "http://localhost:3000/dashboard",
            "https://preprod.labvira.co.uk/dashboard",
          ],
          redirectSignOut: [
            "http://localhost:3000",
            "https://preprod.labvira.co.uk",
          ],
          responseType: "code",
        },

        username: false,
        email: true,
      },
    },
  },
});

const RedirectToDefaultDashboardPage = () => {
  useEffect(() => {
    window.location.href = "/dashboard/experiments";
  }, []);
  return null;
};

const theme = extendTheme({
  components: {
    Table: {
      variants: {
        simple: {
          tr: {
            _hover: {
              backgroundColor: "#00000008",
              cursor: "pointer",
            },
          },
        },
      },
    },
  },
});

function App() {
  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route
            path="/dashboard/*"
            element={
              <Authenticator className="mt-20">
                <DashboardLayout />
              </Authenticator>
            }
          >
            <Route path="experiments" element={<ExperimentsPage />} />
            <Route path="analysis" element={<AnalysisPage />} />
            <Route path="experiment-data" element={<>TODO - see App.tsx</>} />
            <Route path="analysis-results" element={<>TODO - see App.tsx</>} />
            <Route path="*" element={<RedirectToDefaultDashboardPage />} />
          </Route>
          <Route path="/*" element={<Header />}>
            <Route path="" element={<LandingPage />} />
            <Route path="login" element={<>login</>} />
            <Route path="sign-up" element={<>sign up</>} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
