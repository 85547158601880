import {
  fetchAuthSession,
  fetchUserAttributes,
  signOut,
} from "@aws-amplify/auth";

const getFullName = async () => {
  const response = await fetchUserAttributes();
  const firstName = response.given_name;
  const surName = response.family_name;
  return `${firstName} ${surName}`;
};

const getIdToken = async () => {
  const response = await fetchAuthSession();
  return response.tokens?.idToken;
};

const logOut = async () => {
  await signOut();
};

export { getFullName, getIdToken, logOut };
