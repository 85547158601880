interface Variables {
  independentVariable: string;
  dependentVariables: string[];
}

enum FileError {
  WRONG_FILE_TYPE = 1,
  WRONG_FILE_FORMAT = 2,
}

const allowableFileTypes = [
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "text/csv",
];

const detectVariables = (file: File): Variables => {
  if (!allowableFileTypes.includes(file.type)) {
    throw FileError.WRONG_FILE_TYPE;
  }
  return {
    independentVariable: "Something",
    dependentVariables: ["something else"],
  };
};

export { detectVariables, FileError };
