const Demo = () => {
  return (
    <div className="relative">
      <div className="absolute left-[50%] top-0 bottom-0 bg-beige w-[100vw] -z-10 -translate-x-2/4"></div>

      <div className="flex flex-col md:flex-row gap-10 py-20">
        <div className="flex-[40] self-center">
          <div className="md:py-20">
            <p className="text-red-700 font-poppins font-bold">DEMO</p>
            <h2 className="font-prosto text-4xl font-semibold">
              Watch our demo
            </h2>
            <p className="font-poppins font-light mt-2">
              Dive right into the features we offer. In this video we’ll walk
              you though creating an experiment, specifying your requirements,
              and running tests on the data. Want to know more? Check out our
              YouTube channel where you can find a whole host of FAQs and
              helpful tutorials.
            </p>
          </div>
        </div>
        <div className="flex-[60] self-center">
          <video className="h-full w-full" controls>
            <source
              src="https://www.shutterstock.com/shutterstock/videos/3529545287/preview/stock-footage-abstract-animation-background-with-color-refraction-effect-k-seamless-looped-video-d-render.mp4"
              type="video/mp4"
            ></source>
          </video>
        </div>
      </div>
    </div>
  );
};

export default Demo;
