import { Experiment } from "../types/types";
import { getIdToken } from "../utils/Utils";

const apiUrl: string = process.env.REACT_APP_PLATFORM_API ?? "API URL NOT SET";
const ApiKey: string =
  process.env.REACT_APP_PLATFORM_API_KEY ?? "API KEY NOT SET";

const CreateExperiment = async function (experiment: Experiment) {
  const response = await fetch(apiUrl + "/experiment", {
    method: "POST",
    headers: {
      "x-api-key": ApiKey,
      Authorization: "Bearer " + getIdToken(),
    },
    body: JSON.stringify(experiment),
  }).then((data) => {
    if (data.status === 200) {
      return "";
    } else {
      throw new Error("Non 2XX response received");
    }
  });
  return response;
};

export { CreateExperiment };
