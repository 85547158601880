import { IconButton } from "@chakra-ui/react";
import SearchBox from "../../searchBox/SearchBox";
import { ReactNode } from "react";
import { HamburgerIcon } from "@chakra-ui/icons";

interface ContentProps {
  children?: ReactNode;
  openMobileDrawer: () => void;
}

const Content = ({ children, openMobileDrawer }: ContentProps) => {
  return (
    <>
      <div className="w-full min-h-[100vh]">
        <div className="w-[90%] max-w-[1200px] mx-auto px-5 md:px-0">
          {/* Mobile header links */}
          <div className="flex flex-row gap-4">
            <div className="flex-none self-center lg:hidden">
              <IconButton
                borderColor={"#00000030"}
                _hover={{
                  backgroundColor: "#00000010",
                  borderColor: "#00000050",
                }}
                size={"md"}
                variant={"outline"}
                aria-label="Open header menu"
                icon={<HamburgerIcon />}
                onClick={openMobileDrawer}
              />
            </div>

            <SearchBox />
          </div>
          <div>{children}</div>
        </div>
      </div>
    </>
  );
};

export default Content;
