import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

const LiveExperimentsTable = () => {
  return (
    <>
      <TableContainer className="mt-5">
        <Table variant={"simple"} size={"sm"}>
          <Thead>
            <Tr className="!bg-transparent !cursor-default">
              <Th>Name</Th>
              <Th>Created By</Th>
              <Th>Last Contribution</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>A/B Experiment</Td>
              <Td>Mark Alston</Td>
              <Td>3 days ago</Td>
            </Tr>
            <Tr>
              <Td>Thermistor sensitivity</Td>
              <Td>Oliver Feighn</Td>
              <Td>Just now</Td>
            </Tr>
            <Tr>
              <Td>My final experiment</Td>
              <Td>Thomas Cook</Td>
              <Td>1 month ago</Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
};

export default LiveExperimentsTable;
