const Footer = () => {
  return (
    <div className="relative">
      <div className="absolute left-[50%] top-0 bottom-0 bg-[#1A202C] w-[100vw] -z-10 -translate-x-2/4"></div>
      <div className="py-20 flex flex-col gap-2">
        <h2 className="text-white font-prosto text-xl pb-10 font-bold">
          Labvira
        </h2>
        <a
          className="text-white font-poppins text-sm font-light"
          href="/dashboard"
        >
          Dashboard
        </a>
        <a
          className="text-white font-poppins text-sm font-light"
          href="/contact-us"
        >
          Contact Us
        </a>
        <a
          className="text-white font-poppins text-sm font-light"
          href="/developer-documentation"
        >
          Developer Docs
        </a>
      </div>
    </div>
  );
};

export default Footer;
